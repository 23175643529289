import React, { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { createHelp } from "../api/helpsApi";
import setCookies from "../hooks/setCoocies";
import getCookies from "../hooks/getCookies";

const Help: React.FC = () => {
  const [help, setHepl] = useState({
    help_text: "",
    contact: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);
  const helpHandle = async (e: any) => {
    e.preventDefault();
    if (help.help_text !== "") {
      await createHelp(help);
      setCookies("help_cookies", true, 1);
      setShowAlert(true);
      setHepl({
        help_text: "",
        contact: "",
      });
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    } else {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  };

  const help_cookies = getCookies("help_cookies");
  return (
    <>
      <h1 className="header-text">Hulp nodig?</h1>
      <div className="text-center">
        {help_cookies !== "true" ? (
          <>
            <p className="p-div">
              {" "}
              {showAlert && (
                <Alert id="success-alert" key={"success"} variant={"success"}>
                  Uw bericht is gestuurd.
                </Alert>
              )}
              {showError && (
                <Alert id="danger-alert" key={"danger"} variant={"danger"}>
                  Error.
                </Alert>
              )}
              Kan je iets echt niet vinden of zit je bij iets vast? Laat het ons
              weten!
            </p>
            <Form.Control
              required
              style={{ marginTop: "12px" }}
              value={help.contact}
              onChange={(e) =>
                setHepl((h) => ({ ...h, contact: e.target.value }))
              }
              type="text"
              placeholder="Jouw naam, telefoonnummer en/of e-mailadres"
            />
            <Form.Control
              required
              value={help.help_text}
              onChange={(e) =>
                setHepl((h) => ({ ...h, help_text: e.target.value }))
              }
              as="textarea"
              placeholder="Jouw vraag"
              style={{ height: "100px", marginTop: "12px" }}
            />
            <Button
              className="buttons"
              onClick={(e) => helpHandle(e)}
              variant="dark"
            >
              Verzenden
            </Button>
          </>
        ) : (
          <p className="p-div">
            Bedankt voor jouw vraag.
            <br />
            Wij nemen deze op in een lijst van veel gestelde vragen (FAQ) die
            dan weer op deze pagina getoond zullen worden.
            <br /> Als het een cruciale vraag is, dan nemen wij nog contact met
            je op. <br />
            Niks van ons vernomen? Vraag Switch dan even om meer info.
          </p>
        )}
      </div>
    </>
  );
};

export default Help;
