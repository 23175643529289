import React, { useContext } from "react";
import { MyContext } from "../App";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const BackBtn = () => {
  const { history } = useContext(MyContext);
  const goBack = () => {
    history(-1);
  };
  return (
    <Button variant="dark" className="back-button" onClick={goBack}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </Button>
  );
};

export default BackBtn;
