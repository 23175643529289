import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { allDishes, editDish, getOneDish } from "../api/dishesApi";
import { Button, Col, Form, Row } from "react-bootstrap";
import { MyContext } from "../App";
import MarkdownEditor from "@uiw/react-markdown-editor";
import BackBtn from "../components/BackBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faClock,
  faEuroSign,
  faPerson,
} from "@fortawesome/free-solid-svg-icons";

const EditDish = () => {
  const { id } = useParams();
  const { setDishAllData, setDishCopyData, history } = useContext(MyContext);
  type DishItem = {
    name: string;
    recept: string;
    date: string;
    newImage: string | FormData;
    oldImage: string;
    ingredients: any;
    cooking_time: number;
    price: string;
    persons: number;
  };
  const [oneDishItem, setOneDishItem] = useState<DishItem>({
    name: "",
    recept: "",
    date: "",
    newImage: "",
    oldImage: "",
    ingredients: "",
    cooking_time: 0,
    price: "",
    persons: 1,
  });

  useEffect(() => {
    getOneDish(id).then((data) => {
      setOneDishItem({
        name: data.name,
        recept: data.recept,
        date: data.date,
        newImage: "",
        oldImage: data.image,
        ingredients: data.ingredients,
        cooking_time: data.cooking_time,
        price: data.price,
        persons: data.persons,
      });
    });
  }, [id]);

  const updateDishs = async () => {
    await editDish(id, oneDishItem);
    allDishes().then((data) => {
      setDishAllData(data);
      setDishCopyData(data);
    });
    history("/alle");
  };

  const imageHandler = (e: any) => {
    setOneDishItem((p: any) => ({ ...p, newImage: e.target.files[0] }));
    //IMAGE OUTPUT
    const output: any = document.getElementById("output");
    if (e.target.files.length > 0) {
      // Get the selected file
      const selectedFile = e.target.files[0];
      output.style.backgroundImage = `url(${URL.createObjectURL(
        selectedFile
      )})`;
      URL.revokeObjectURL(selectedFile);
    }
  };

  return (
    <section>
      <h1 className="header-text">Bijwerken {oneDishItem.name}</h1>
      <BackBtn />
      <div className="single-dishes-admin">
        <div
          id="output"
          className="div-img"
          style={{
            backgroundImage: `url(${
              `${process.env.REACT_APP_BASE_URL}/upload/` + oneDishItem.oldImage
            })`,
          }}
        ></div>
        <Form>
          <Form.Group
            className="inputs-add"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Control
              required
              value={oneDishItem.name}
              type="text"
              onChange={(e) => {
                setOneDishItem((p: any) => ({ ...p, name: e.target.value }));
              }}
            />
          </Form.Group>
          <Row className="inputs-add px-5">
            <Col className="col-1">
              <FontAwesomeIcon size="lg" icon={faCalendarDays} />
            </Col>
            <Col className="col-5">
              <b>{oneDishItem.date.slice(0, 10)}</b>
            </Col>
            <Col className="col-6">
              <Form.Control
                type="date"
                onChange={(e) =>
                  setOneDishItem((p: any) => ({ ...p, date: e.target.value }))
                }
              />
            </Col>
          </Row>
          <Row className="inputs-add px-5">
            <Col className="col-1">
              <FontAwesomeIcon size="lg" icon={faClock} />
            </Col>
            <Col className="col-5">
              <b>{oneDishItem.cooking_time}</b>&nbsp;
              <small>min</small>
            </Col>
            <Col className="col-6">
              <Form.Control
                placeholder="Bereidingstijd"
                type="number"
                onChange={(e) =>
                  setOneDishItem((p: any) => ({
                    ...p,
                    cooking_time: e.target.value,
                  }))
                }
              />
            </Col>
          </Row>
          <Row className="inputs-add px-5">
            <Col className="col-1">
              <FontAwesomeIcon size="lg" icon={faEuroSign} />
            </Col>
            <Col className="col-5">
              <b>{oneDishItem.price}</b>&nbsp;
              <small>p.p.</small>
            </Col>
            <Col className="col-6">
              <Form.Control
                placeholder="Kostprijs p.p."
                type="number"
                step={"any"}
                onChange={(e) =>
                  setOneDishItem((p: any) => ({ ...p, price: e.target.value }))
                }
              />
            </Col>
          </Row>
          <Row className="inputs-add px-5">
            <Col className="col-1">
              <FontAwesomeIcon size="lg" icon={faPerson} />
            </Col>
            <Col className="col-5">
              <b>{oneDishItem.persons}</b>&nbsp;
              <small>{oneDishItem.persons > 1 ? "personen" : "persoon"}</small>
            </Col>
            <Col className="col-6">
              <Form.Control
                placeholder="Aantal personen"
                type="number"
                step={"any"}
                onChange={(e) =>
                  setOneDishItem((p: any) => ({
                    ...p,
                    persons: e.target.value,
                  }))
                }
              />
            </Col>
          </Row>
          <Form.Group className="inputs-add">
            <Form.Control
              as="textarea"
              value={oneDishItem.ingredients}
              onChange={(e) =>
                setOneDishItem((p: any) => ({
                  ...p,
                  ingredients: e.target.value,
                }))
              }
            />
          </Form.Group>
          <Form.Group
            className="inputs-add"
            controlId="exampleForm.ControlTextarea1"
          >
            <div className="App inputs-add" data-color-mode="light">
              <MarkdownEditor
                toolbars={[
                  "undo",
                  "redo",
                  "bold",
                  "italic",
                  "header",
                  "underline",
                  "quote",
                  "strike",
                  "olist",
                  "ulist",
                  "strike",
                ]}
                value={oneDishItem.recept}
                onChange={(value) => {
                  setOneDishItem((p) => ({ ...p, recept: value }));
                }}
              />
            </div>
          </Form.Group>
          <Form.Group controlId="formFile" className="inputs-add">
            <Form.Control
              required
              accept="image/* , multipart/form-data"
              name="image"
              capture="environment"
              onChange={imageHandler}
              type="file"
            />
          </Form.Group>
          <Form.Group className="d-flex justify-content-between ">
            <Button onClick={updateDishs} variant="success">
              Opslaan
            </Button>
            <Button onClick={() => history(-1)} variant="danger">
              Annuleren
            </Button>
          </Form.Group>
        </Form>
      </div>
    </section>
  );
};

export default EditDish;
