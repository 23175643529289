import { KeyboardEvent, useContext, useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { MyContext } from "../App";
import Cookies from "js-cookie";

const Login = () => {
  const { setAuthentication, authentication, history } = useContext(MyContext);
  const [validated, setValidated] = useState(false);
  const [showError, setShowError] = useState(false);
  const [admin, setAdmin] = useState({
    username: "",
    password: "",
  });
  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const adminSubmit = () => {
    if (admin.username && admin.password) {
      if (
        (admin.username === process.env.REACT_APP_USER_ADMIN &&
          admin.password === process.env.REACT_APP_PASSWORD_ADMIN) ||
        (admin.username === process.env.REACT_APP_USER_CHEF &&
          admin.password === process.env.REACT_APP_PASSWORD_CHEF)
      ) {
        setAuthentication(true);
        Cookies.set("user", "userTrue", { expires: 1 });
      } else setShowError(true);
    }
  };

  const loginBtn = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      if (admin.username && admin.password) {
        if (
          (admin.username === process.env.REACT_APP_USER_ADMIN &&
            admin.password === process.env.REACT_APP_PASSWORD_ADMIN) ||
          (admin.username === process.env.REACT_APP_USER_CHEF &&
            admin.password === process.env.REACT_APP_PASSWORD_CHEF)
        ) {
          setAuthentication(true);
          Cookies.set("user", "userTrue", { expires: 1 });
        } else setShowError(true);
      }
      return null;
    }
  };

  return (
    <>
      {authentication ? (
        <h1 className="header-text">
          {" "}
          Welkom,{" "}
          {admin.username === process.env.REACT_APP_USER_ADMIN
            ? process.env.REACT_APP_USER_ADMIN
            : process.env.REACT_APP_USER_CHEF}
        </h1>
      ) : (
        <h1 className="header-text">Log in</h1>
      )}
      <div>
        {!authentication ? (
          <>
            <div>
              {showError && (
                <Alert id="danger-alert" key={"danger"} variant={"danger"}>
                  Gebruikersnaam of wachtwoord is fout.
                </Alert>
              )}
            </div>
            <Form
              style={{ textAlign: "center" }}
              noValidate
              validated={validated}
              onChange={handleSubmit}
            >
              <Form.Control
                required
                className="placeholderInputs login-inputs"
                value={admin.username}
                onChange={(e: any) =>
                  setAdmin((p) => ({ ...p, username: e.target.value }))
                }
                type="text"
                placeholder="Gebruikersnaam"
              />
              <Form.Control.Feedback type="invalid">
                Please choose a username.
              </Form.Control.Feedback>
              <Form.Control
                required
                onKeyDown={loginBtn}
                className="placeholderInputs login-inputs"
                value={admin.password}
                onChange={(e: any) =>
                  setAdmin((p) => ({ ...p, password: e.target.value }))
                }
                type="password"
                placeholder="Wachtwoord"
              />
              <Form.Control.Feedback type="invalid">
                Please choose a password.
              </Form.Control.Feedback>
              <Button className="buttons" onClick={adminSubmit} variant="dark">
                Log in
              </Button>
            </Form>
          </>
        ) : (
          <>
            <div className="p-div">
              Welkom bij het admin portaal van Switch Dish. Vanuit hier kan je
              gerechten en recepten toevoegen, alle gerechten inzien en bewerken
              en hulp en suggesties van gebruikers inlezen en beantwoorden.
            </div>
            <div className="login-div">
              <h4>Waar wil je naartoe?</h4>
              <Row>
                <Col className="col-12 col-sm-6">
                  <button onClick={() => history("/toevoegen")}>
                    Gerecht toevoegen
                  </button>
                </Col>
                <Col className="col-12 col-sm-6">
                  <button onClick={() => history("/alle")}>
                    Alle gerechten
                  </button>
                </Col>
                <Col className="col-12 col-sm-6">
                  <button onClick={() => history("/hulp")}>
                    Hulp database
                  </button>
                </Col>
                <Col className="col-12 col-sm-6">
                  <button onClick={() => history("/idee")}>
                    Ideeëndoos database
                  </button>
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Login;
