import { useEffect, useState } from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { allDishes, ratingDish } from "../api/dishesApi";
import setCookies from "../hooks/setCoocies";
import getCookies from "../hooks/getCookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import {
  faCalendarDays,
  faClock,
  faEuroSign,
} from "@fortawesome/free-solid-svg-icons";
const History: React.FC = () => {
  function ControlledCarousel() {
    // eslint-disable-next-line
    const [hoverOne, setHoverOne] = useState(0);
    // eslint-disable-next-line
    const [hoverTwo, setHoverTwo] = useState(0);
    // eslint-disable-next-line
    const [hoverTree, setHoverTree] = useState(0);

    const [index, setIndex] = useState(0);

    type Dishesdata = {
      date: string;
      id: Number;
      image: string;
      ingredients: string;
      name: string;
      rate: Number;
      recept: string;
      cooking_time: string;
      price: string;
      persons: number;
    };
    const [lastOne, setLastOne] = useState<Dishesdata>({
      date: "",
      id: 0,
      image: "",
      ingredients: "",
      name: "",
      rate: 0,
      recept: "",
      cooking_time: "",
      price: "",
      persons: 1,
    });
    const [lastTwo, setLasTtwo] = useState<Dishesdata>({
      date: "",
      id: 0,
      image: "",
      ingredients: "",
      name: "",
      rate: 0,
      recept: "",
      cooking_time: "",
      price: "",
      persons: 1,
    });
    const [lastTree, setLastTree] = useState<Dishesdata>({
      date: "",
      id: 0,
      image: "",
      ingredients: "",
      name: "",
      rate: 0,
      recept: "",
      cooking_time: "",
      price: "",
      persons: 1,
    });
    const [rateDataOne, setRateDataOne] = useState({
      id: 0,
      rate_number: 0,
    });
    const [rateDataTwo, setRateDataTwo] = useState({
      id: 0,
      rate_number: 0,
    });
    const [rateDataTree, setRateDataTree] = useState({
      id: 0,
      rate_number: 0,
    });

    const [rateOne, setRateOne] = useState(0);
    const [rateTwo, setRateTwo] = useState(0);
    const [rateTree, setRateTree] = useState(0);

    const [counterOne, setCounterOne] = useState(5);
    const [counterTwo, setCounterTwo] = useState(5);
    const [counterTree, setCounterTree] = useState(5);

    const [timerRunningOne, setTimerRunningOne] = useState(false);
    const [timerRunningTwo, setTimerRunningTwo] = useState(false);
    const [timerRunningTree, setTimerRunningTree] = useState(false);

    const [lastClickedRatingOne, setLastClickedRatingOne] = useState(0);
    const [lastClickedRatingTwo, setLastClickedRatingTwo] = useState(0);
    const [lastClickedRatingTree, setLastClickedRatingTree] = useState(0);

    const days = [
      "Maandag",
      "Dinsdag",
      "Woensdag",
      "Donderdag",
      "Vrijdag",
      "Zaterdag",
      "Zondag",
    ];
    //     get date
    const today = new Date();
    const dayNames = (date: any) => new Date(date.toString()).getDay();

    let currentDay = String(today.getDate()).padStart(2, "0");
    let currentMonth = String(today.getMonth() + 1).padStart(2, "0");
    let currentYear = today.getFullYear();
    let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

    useEffect(() => {
      allDishes()
        .then((data) => {
          const newData = data.map((item: any) =>
            item.date <= currentDate ? item : null
          );
          return newData.filter((a: any) => a !== null);
        })
        .then((data) => {
          if (data[0]) {
            setLastOne({
              date: data[0].date ? data[0].date : "",
              id: data[0].id,
              image: data[0].image,
              ingredients: data[0].ingredients,
              name: data[0].name,
              rate: data[0].rate,
              recept: data[0].recept,
              cooking_time: data[0].cooking_time,
              price: data[0].price,
              persons: data[0].persons,
            });
          }
          if (data[1]) {
            setLasTtwo({
              date: data[1].date,
              id: data[1].id,
              image: data[1].image,
              ingredients: data[1].ingredients,
              name: data[1].name,
              rate: data[1].rate,
              recept: data[1].recept,
              cooking_time: data[1].cooking_time,
              price: data[1].price,
              persons: data[1].persons,
            });
          }
          if (data[2]) {
            setLastTree({
              date: data[2].date,
              id: data[2].id,
              image: data[2].image,
              ingredients: data[2].ingredients,
              name: data[2].name,
              rate: data[2].rate,
              recept: data[2].recept,
              cooking_time: data[1].cooking_time,
              price: data[2].price,
              persons: data[2].persons,
            });
          }
        });
    }, []);

    useEffect(() => {
      if (timerRunningOne && counterOne > 0) {
        const timer = setInterval(() => {
          setCounterOne((prevCounter) => prevCounter - 1);
        }, 1000);

        return () => {
          clearInterval(timer);
        };
      }
      ratingDish(rateDataOne);
      setCookies(
        `ratedDishes${rateDataOne.id}`,
        JSON.stringify([rateDataOne.id, rateDataOne.rate_number]),
        3
      );
    }, [counterOne, timerRunningOne, rateDataOne]);

    useEffect(() => {
      if (timerRunningTwo && counterTwo > 0) {
        const timer = setInterval(() => {
          setCounterTwo((prevCounter) => prevCounter - 1);
        }, 1000);

        return () => {
          clearInterval(timer);
        };
      }
      ratingDish(rateDataTwo);
      setCookies(
        `ratedDishes${rateDataTwo.id}`,
        JSON.stringify([rateDataTwo.id, rateDataTwo.rate_number]),
        3
      );
    }, [counterTwo, timerRunningTwo, rateDataTwo]);

    useEffect(() => {
      if (timerRunningTree && counterTree > 0) {
        const timer = setInterval(() => {
          setCounterTree((prevCounter) => prevCounter - 1);
        }, 1000);

        return () => {
          clearInterval(timer);
        };
      }
      ratingDish(rateDataTree);
      setCookies(
        `ratedDishes${rateDataTree.id}`,
        JSON.stringify([rateDataTree.id, rateDataTree.rate_number]),
        3
      );
    }, [counterTree, timerRunningTree, rateDataTree]);

    const handleSelect = (selectedIndex: number) => {
      setIndex(selectedIndex);
    };
    const cookieDishOne = getCookies(`ratedDishes${lastOne.id}`);
    const cookieDishTwo = getCookies(`ratedDishes${lastTwo.id}`);
    const cookieDishTree = getCookies(`ratedDishes${lastTree.id}`);

    const handleRatingone = (id: any, count: number) => {
      if (counterOne > 0 && count !== lastClickedRatingOne) {
        setTimerRunningOne(true);
        setCounterOne(5);
        setLastClickedRatingOne(count);
        setRateDataOne({
          id: id,
          rate_number: count,
        });
      }
      setRateOne(count);
    };

    const handleRatingTwo = async (id: any, count: number) => {
      if (counterTwo > 0 && count !== lastClickedRatingTwo) {
        setTimerRunningTwo(true);
        setCounterTwo(5);
        setLastClickedRatingTwo(count);
        setRateDataTwo({
          id: id,
          rate_number: count,
        });
      }
      setRateTwo(count);
    };

    const handleRatingTree = async (id: any, count: number) => {
      if (counterTree > 0 && count !== lastClickedRatingTree) {
        setTimerRunningTree(true);
        setCounterTree(5);
        setLastClickedRatingTree(count);
        setRateDataTree({
          id: id,
          rate_number: count,
        });
      }
      setRateTree(count);
    };

    const arrOne = lastOne.ingredients
      .split(",")
      .filter((a) => a !== " ")
      .filter((b) => b !== "\r\n");
    const arrTwo = lastTwo.ingredients
      .split(",")
      .filter((a) => a !== " ")
      .filter((b) => b !== "\r\n");

    const arrTree = lastTree.ingredients
      .split(",")
      .filter((a) => a !== " ")
      .filter((b) => b !== "\r\n");

    const HistoryItems = (
      item: any,
      hover: any,
      rate: any,
      counter: any,
      cookie: any,
      arr: any,
      handleClick: any
    ) => {
      return (
        <article className="history-style ">
          <div className="history-dishes">
          <Link to={`/${item.id}`}>
            <div
              className="history-img"
              style={{
                backgroundImage: `url(${
                  `${process.env.REACT_APP_BASE_URL}/upload/` + item.image
                })`,
              }}
            ></div>
            <h3>{item.name}</h3>
            </Link>
            {cookie?.includes(JSON.stringify(item.id)) || undefined ? (
              <>
                {[...Array(5)].map((s, index) => {
                  const count = index + 1;
                  return (
                    <label key={index}>
                      <input type="radio" name="rating" />
                      {cookie !== undefined &&
                      cookie.slice(-2, -1) >= JSON.stringify(count) ? (
                        <svg
                          className="star"
                          xmlns="http://www.w3.org/2000/svg"
                          height="2em"
                          viewBox="0 0 576 512"
                        >
                          <path
                            className="bg-warning"
                            d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="star"
                          xmlns="http://www.w3.org/2000/svg"
                          height="2em"
                          viewBox="0 0 576 512"
                        >
                          <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                        </svg>
                      )}
                    </label>
                  );
                })}
                <p>Je hebt dit gerecht al beoordeeld</p>
              </>
            ) : (
              <>
                {[...Array(5)].map((s, index) => {
                  const count = index + 1;
                  return (
                    <label key={index}>
                      <input
                        type="radio"
                        value={count}
                        name="rating"
                        onClick={() => handleClick(item.id, count)}
                      />
                      {count <= (hover || rate) ? (
                        <svg
                          className="star"
                          xmlns="http://www.w3.org/2000/svg"
                          height="2em"
                          viewBox="0 0 576 512"
                        >
                          <path
                            className="bg-warning"
                            d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                          />
                        </svg>
                      ) : (
                        <svg
                          className="star"
                          xmlns="http://www.w3.org/2000/svg"
                          height="2em"
                          viewBox="0 0 576 512"
                        >
                          <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                        </svg>
                      )}
                    </label>
                  );
                })}
                <small className="teller">
                  {counter === 0 ? (
                    <p>Je stem is geregistreerd.</p>
                  ) : (
                    <p>
                      Je stem wordt na <b>{counter}</b> seconden geregistreerd.
                    </p>
                  )}
                </small>
              </>
            )}
          </div>
          <div>
            <div className="h-text">
              <Row className="history-header">
                <Col md={12} lg={5}>
                  <p>Bereidingstijd:&nbsp;{item.cooking_time}&nbsp;minuten</p>
                </Col>
                <Col md={12} lg={5}>
                  <p>Kostprijs:&nbsp;€&nbsp;{item.price},00 pp</p>
                </Col>
              </Row>
              <p className="mb-2">
                <b>Aantal personen:</b> {item.persons}&nbsp;
                {item.persons > 1 ? "personen" : "persoon"}
              </p>
              <b>Ingrediënten:</b>
              <div className="App" data-color-mode="light">
                <ul className="ingredient-ul-list">
                  {arr?.map((ingredient: any, i: number) => {
                    return <li key={i}>{ingredient}</li>;
                  })}
                </ul>
                <b>Recept:</b>
                <div className="App m-2" data-color-mode="light">
                  <MarkdownEditor.Markdown source={item.recept} />
                </div>
              </div>
            </div>
          </div>
        </article>
      );
    };

    return (
      <>
        <h1 className="header-text">Geschiedenis</h1>
        <Carousel
          interval={null}
          variant="dark"
          activeIndex={index}
          onSelect={handleSelect}
        >
          <Carousel.Item>
            <h4>
              {days[dayNames(lastOne.date) - 1]} ({lastOne.date})
            </h4>
            {HistoryItems(
              lastOne,
              hoverOne,
              rateOne,
              counterOne,
              cookieDishOne,
              arrOne,
              handleRatingone
            )}
          </Carousel.Item>

          <Carousel.Item>
            <h4>
              {days[dayNames(lastTwo.date) - 1]} ({lastTwo.date})
            </h4>
            {HistoryItems(
              lastTwo,
              hoverTwo,
              rateTwo,
              counterTwo,
              cookieDishTwo,
              arrTwo,
              handleRatingTwo
            )}
          </Carousel.Item>

          <Carousel.Item>
            <h4>
              {days[dayNames(lastTree.date) - 1]} ({lastTree.date})
            </h4>
            {HistoryItems(
              lastTree,
              hoverTree,
              rateTree,
              counterTree,
              cookieDishTree,
              arrTree,
              handleRatingTree
            )}
          </Carousel.Item>
        </Carousel>
      </>
    );
  }
  return <ControlledCarousel />;
};

export default History;
