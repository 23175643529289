import { KeyboardEvent, useContext, useEffect, useState } from "react";
import {
  Button,
  CloseButton,
  Col,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { addNewDish, allDishes } from "../api/dishesApi";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { MyContext } from "../App";
import BackBtn from "../components/BackBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faEuroSign,
  faPerson,
  faTurnDown,
} from "@fortawesome/free-solid-svg-icons";

const AddDishes: React.FC = () => {
  type dishesObjectType = {
    name: string;
    recept: string;
    date: string;
    image: FormData | string;
    ingredients: any;
    cooking_time: number;
    price: number;
    persons: number;
  };
  const [dishData, setDishData] = useState<dishesObjectType>({
    name: "",
    recept: "",
    date: new Date().toISOString().split("T")[0],
    image: "",
    ingredients: "",
    cooking_time: 0,
    price: 0,
    persons: 1,
  });
  const [addIngredient, setaddIngredient] = useState("");
  const [ingredient, setIngredient] = useState<any>([]);
  const [deletedItems, setDeletedItemst] = useState<any>([]);
  const { setDishAllData, setDishCopyData, history } = useContext(MyContext);
  const [value, setValue] = useState("");
  const [validated, setValidated] = useState(false);

  const imageHandler = (e: any) => {
    setDishData((p: any) => ({ ...p, image: e.target.files[0] }));
    //IMAGE OUTPUT
    const output: any = document.getElementById("output");
    output.src = URL.createObjectURL(e.target.files[0]);
    output.onload = function () {
      URL.revokeObjectURL(output.src);
    };
  };
  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const addIngredientBtn = (e: KeyboardEvent<HTMLElement>) => {
    const trimmedInput = addIngredient.trim();
    if (trimmedInput.length && (e.key === "Enter" || e.key === ",")) {
      e.preventDefault();
      setIngredient([...ingredient, addIngredient]);
      setaddIngredient("");
    }
    return null;
  };

  const addHandleBtn = () => {
    if (addIngredient) {
      setIngredient([...ingredient, addIngredient]);
      setaddIngredient("");
    }
    return null;
  };

  const addNew = async () => {
    await addNewDish(dishData);
    allDishes().then((data) => {
      setDishAllData(data);
      setDishCopyData(data);
    });
    setDishData({
      name: "",
      recept: "",
      date: new Date().toISOString().split("T")[0],
      image: "",
      ingredients: "",
      cooking_time: 0,
      price: 0,
      persons: 1,
    });
    setValue("");
    setIngredient("");
    history("/alle");
  };

  return (
    <section>
      <h1 className="header-text">Gerecht toevoegen</h1>
      <BackBtn />
      <p className="p-div">
        Heb je vandaag een lekker gerecht gemaakt en wil je het toevoegen aan
        deze website? Vul dit formulier in!
      </p>
      <Form
        className="text-start"
        noValidate
        validated={validated}
        onChange={handleSubmit}
      >
        <Form.Group
          className="inputs-add"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Control
            placeholder="Naam gerecht"
            required
            value={dishData.name}
            type="text"
            onChange={(e) => {
              setDishData((p: any) => ({ ...p, name: e.target.value }));
            }}
          />
        </Form.Group>
        <Row>
          <Col className="col-12 add-padding" md={3}>
            <Form.Control
              required
              type="date"
              value={dishData.date}
              onChange={(e) =>
                setDishData((p: any) => ({ ...p, date: e.target.value }))
              }
            />
          </Col>
          <Col className="col-12 add-padding" md={3}>
            <InputGroup>
              <Form.Control
                required
                type="number"
                placeholder="Bereidingstijd"
                onChange={(e) =>
                  setDishData((p: any) => ({
                    ...p,
                    cooking_time: e.target.value,
                  }))
                }
              />
              <InputGroup.Text>
                <FontAwesomeIcon size="sm" icon={faClock} />
              </InputGroup.Text>
            </InputGroup>
          </Col>

          <Col className="col-12 add-padding" md={3}>
            <InputGroup>
              <Form.Control
                required
                type="number"
                step={"any"}
                placeholder="Kostprijs p.p."
                onChange={(e) =>
                  setDishData((p: any) => ({ ...p, price: e.target.value }))
                }
              />
              <InputGroup.Text>
                <FontAwesomeIcon size="sm" icon={faEuroSign} />
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col className="col-12 add-padding" md={3}>
            <InputGroup>
              <Form.Control
                required
                type="number"
                placeholder="Aantal personen"
                onChange={(e) =>
                  setDishData((p: any) => ({
                    ...p,
                    persons: e.target.value,
                  }))
                }
              />
              <InputGroup.Text>
                <FontAwesomeIcon size="sm" icon={faPerson} />
              </InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
        <InputGroup>
          <Form.Control
            placeholder="Ingredienten (Zet een komma na elk ingrediënt om het toe te voegen aan de lijst)"
            value={addIngredient}
            onChange={(e: any) => setaddIngredient(e.target.value)}
            onKeyDown={addIngredientBtn}
            as="textarea"
            style={{ height: "30px" }}
          />
          <Button variant="dark" onClick={addHandleBtn}>
            <FontAwesomeIcon
              className="add-ingredient-icon"
              icon={faTurnDown}
            />
          </Button>
        </InputGroup>
        <div>
          {ingredient?.map((item: any, i: number) => {
            return (
              <Button variant="light" className="m-2" key={i}>
                {item}{" "}
                <CloseButton
                  onClick={() => {
                    setIngredient((prev: any) =>
                      prev.filter((i: any) => i !== item)
                    );
                  }}
                />
              </Button>
            );
          })}
        </div>
        <Form.Group
          className="inputs-add"
          controlId="exampleForm.ControlTextarea1"
        >
          <div className="App " data-color-mode="light">
            <MarkdownEditor
              toolbars={[
                "undo",
                "redo",
                "bold",
                "italic",
                "header",
                "underline",
                "quote",
                "strike",
                "olist",
                "ulist",
                "strike",
              ]}
              value={value}
              height="200px"
              placeholder="Recept"
              onChange={(value) => {
                setValue(value);
                setDishData((p: any) => ({ ...p, recept: value }));
                setDishData((p: any) => ({ ...p, ingredients: ingredient }));
              }}
            />
          </div>
        </Form.Group>
        <Form.Group className="inputs-add">
          <div className="input-group custom-file-button">
            <label className="input-group-text" htmlFor="inputGroupFile">
              Foto kiezen
            </label>
            <Form.Control
              id="inputGroupFile"
              className="form-control"
              required
              accept="image/* , multipart/form-data"
              name="image"
              onChange={imageHandler}
              type="file"
            />
          </div>
          {/*  eslint-disable-next-line */}
          <img className="upload-image" id="output" />
        </Form.Group>
        <Button className="buttons" variant="dark" onClick={addNew}>
          Toevoegen
        </Button>
      </Form>
    </section>
  );
};

export default AddDishes;
