import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { MyContext } from "../App";
import { Link } from "react-router-dom";
const Search: React.FC = () => {
  const [serchVal, setSerchVal] = useState("");
  const { setDishCopyData, dishCopyData, dishAllData } = useContext(MyContext);
  const [dropdownTitle, setDropdownTitle] = useState("Sorteer op");
  useEffect(() => {
    switch (dropdownTitle) {
      case "Aantal sterren aflopend":
        dishAllData.sort(
          (a: any, b: any) => b.rate / b.rate_members - a.rate / a.rate_members
        );
        break;

      case "Aantal sterren oplopend":
        dishAllData.sort(
          (a: any, b: any) => a.rate / a.rate_members - b.rate / b.rate_members
        );
        break;

      case "Kostprijs aflopend":
        dishAllData.sort((a: any, b: any) => {
          console.log(typeof Number(b.price));
          return Number(b.price) - Number(a.price);
        });

        break;

      case "Kostprijs oplopend":
        dishAllData.sort((a: any, b: any) => {
          return Number(a.price) - Number(b.price);
        });
        break;

      case "Bereidingstijd aflopend":
        dishAllData.sort(
          (a: any, b: any) => Number(b.cooking_time) - Number(a.cooking_time)
        );
        break;

      case "Bereidingstijd oplopend":
        dishAllData.sort(
          (a: any, b: any) => Number(a.cooking_time) - Number(b.cooking_time)
        );
        break;

      default:
        break;
    }
    const temp = dishAllData.filter((d: any) => {
      return (
        d.name.toLowerCase().includes(serchVal.toLowerCase().trim()) ||
        d.ingredients.toLowerCase().includes(serchVal.toLowerCase().trim())
      );
    });

    setDishCopyData(temp);
  }, [serchVal, dropdownTitle]);
  return (
    <>
      <h1 className="header-text">Zoeken</h1>
      <div>
        <p className="p-div">
          Ben je iets aan het zoeken maar kan je het niet vinden? Gebruik deze
          zoekfunctie!
        </p>
        <Form>
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ borderRadius: "0.7rem 0 0 0.7rem" }}
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
              </svg>
            </InputGroup.Text>
            <Form.Control
              type="search"
              value={serchVal}
              placeholder="Zoeken"
              className=" placeholderInputs"
              aria-label="Search"
              onChange={(e) => setSerchVal(e.target.value)}
            />
            <DropdownButton
              variant="dark"
              title={dropdownTitle}
              id="input-group-dropdown-1"
              align="end"
            >
              <Dropdown.Item
                onClick={() => setDropdownTitle("Aantal sterren aflopend")}
                href="#"
              >
                Aantal sterren aflopend
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setDropdownTitle("Aantal sterren oplopend")}
                href="#"
              >
                Aantal sterren oplopend
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setDropdownTitle("Kostprijs aflopend")}
                href="#"
              >
                Kostprijs aflopend
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setDropdownTitle("Kostprijs oplopend")}
                href="#"
              >
                Kostprijs oplopend
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setDropdownTitle("Bereidingstijd aflopend")}
                href="#"
              >
                Bereidingstijd aflopend
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setDropdownTitle("Bereidingstijd oplopend")}
                href="#"
              >
                Bereidingstijd oplopend
              </Dropdown.Item>
            </DropdownButton>
          </InputGroup>
        </Form>
        <Container className="search-result">
          <b>Zoekersultaat:</b>
          <Row style={{ paddingLeft: "6px", paddingRight: "6px" }}>
            {dishCopyData?.map((item: any, i: number) => {
              const { id, name, image } = item;
              return (
                <Col
                  className="col-12 col-sm-6 col-md-4 col-lg-3 search-cols"
                  key={i}
                >
                  <Link to={`/${id}`}>
                    <div
                      className="search-img"
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/upload/${image})`,
                      }}
                    >
                      <h5>{name}</h5>
                    </div>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Search;
