import { useEffect, useState } from "react";
import { Col, Container, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { topTen } from "../api/dishesApi";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEuroSign } from "@fortawesome/free-solid-svg-icons";

const TopDishes: React.FC = () => {
  const [topsTen, setTopsTen] = useState([]);

  const [dropdownTitle, setDropdownTitle] = useState("Sorteer op");

  useEffect(() => {
    topTen().then((data) => {
      switch (dropdownTitle) {
        case "Aantal sterren aflopend":
          data.sort(
            (a: any, b: any) =>
              b.rate / b.rate_members - a.rate / a.rate_members
          );
          break;

        case "Aantal sterren oplopend":
          data.sort(
            (a: any, b: any) =>
              a.rate / a.rate_members - b.rate / b.rate_members
          );
          break;

        case "Kostprijs aflopend":
          data.sort((a: any, b: any) => Number(b.price) - Number(a.price));
          break;

        case "Kostprijs oplopend":
          data.sort((a: any, b: any) => Number(a.price) - Number(b.price));
          break;

        case "Bereidingstijd aflopend":
          data.sort(
            (a: any, b: any) => Number(b.cooking_time) - Number(a.cooking_time)
          );
          break;

        case "Bereidingstijd oplopend":
          data.sort(
            (a: any, b: any) => Number(a.cooking_time) - Number(b.cooking_time)
          );
          break;

        default:
          break;
      }
      return setTopsTen(data);
    });
  }, [dropdownTitle]);

  const topTenDishes: any = topsTen;

  return (
    <div>
      <Row className="top-items">
        <Col md={8}>
          <h1 className="header-text">Top gerechten</h1>
        </Col>
        <Col md={4}>
          <DropdownButton
            variant="dark"
            title={dropdownTitle}
            align="end"
            id="input-group-dropdown-1"
          >
            <Dropdown.Item
              onClick={() => setDropdownTitle("Aantal sterren aflopend")}
              href="#"
            >
              Aantal sterren aflopend
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setDropdownTitle("Aantal sterren oplopend")}
              href="#"
            >
              Aantal sterren oplopend
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setDropdownTitle("Kostprijs aflopend")}
              href="#"
            >
              Kostprijs aflopend
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setDropdownTitle("Kostprijs oplopend")}
              href="#"
            >
              Kostprijs oplopend
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setDropdownTitle("Bereidingstijd aflopend")}
              href="#"
            >
              Bereidingstijd aflopend
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setDropdownTitle("Bereidingstijd oplopend")}
              href="#"
            >
              Bereidingstijd oplopend
            </Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>
      <Container>
        <Row className="top-parent-row">
          <Col
            style={{ paddingRight: "6px", paddingLeft: "6px" }}
            className="col-12 col-lg-6"
          >
            <Container>
              {topTenDishes?.map((item: any, i: number) => {
                const num = i + 1;
                return num < 4 ? (
                  <Row className="top-rows-tree" key={i}>
                    {/* <Col xl={11} className="col-10"> */}
                    <div className="d-flex">
                      <Link to={`/${item.id}`}>
                        <div
                          className="top-img"
                          style={{
                            backgroundImage: `url(${
                              `${process.env.REACT_APP_BASE_URL}/upload/` +
                              item.image
                            })`,
                          }}
                        ></div>
                      </Link>
                      <div className="top-star">
                        <div className="top-star-name">
                          <h5>{item.name}</h5>
                          <p className="rateNum">{num}</p>
                        </div>
                        <Row>
                          <Col className="col-3" lg={6}>
                            <svg
                              className="star"
                              xmlns="http://www.w3.org/2000/svg"
                              height="1em"
                              viewBox="0 0 576 512"
                            >
                              <path
                                className="bg-warning"
                                d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                              />
                            </svg>
                            &nbsp;
                            {item.rate
                              ? (item.rate / item.rate_members).toFixed(1)
                              : 0}
                            /5
                          </Col>

                          <Col>
                            <FontAwesomeIcon size="sm" icon={faClock} />
                            &nbsp;{item.cooking_time}
                          </Col>
                          <Col>
                            <FontAwesomeIcon size="sm" icon={faEuroSign} />
                            &nbsp;{item.price} p.p.
                          </Col>
                        </Row>

                        <small>Aantal stemmen: {item.rate_members}</small>
                      </div>
                    </div>
                  </Row>
                ) : null;
              })}
            </Container>
          </Col>
          <Col
            style={{ paddingRight: "6px", paddingLeft: "6px" }}
            className="col-12 col-lg-6"
          >
            <Container>
              {topTenDishes?.map((item: any, i: number) => {
                const num = i + 1;
                return num < 4 ? null : (
                  <Link key={i} to={`/${item.id}`}>
                    <Row className="top-rows-ten">
                      <Col className="col-10">
                        {/* <b className="top-ten-num">{num}</b>
                        {item.name} <br />
                        <small style={{ paddingLeft: "12px" }}>
                          Aantal stemmen: {item.rate_members}
                        </small> */}
                        <div className="d-flex">
                          <b className="top-ten-num">{num}</b>
                          <p style={{ marginBottom: "0px" }}>{item.name} </p>
                        </div>
                        <small style={{ paddingLeft: "19px" }}>
                          Aantal stemmen: {item.rate_members}
                        </small>
                      </Col>
                      <Col className="col-2">
                        {" "}
                        <svg
                          className="star"
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 576 512"
                        >
                          <path
                            className="bg-warning"
                            d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                          />
                        </svg>{" "}
                        {item.rate
                          ? (item.rate / item.rate_members).toFixed(1)
                          : 0}
                        /5
                      </Col>
                    </Row>
                  </Link>
                );
              })}
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TopDishes;
