import React, { useContext, useEffect, useState } from 'react';
import { MyContext } from '../App';
import { Form } from 'react-bootstrap';

const Select = () => {
    const [selectVal, setSelectVal] = useState("");
    const { dishAllData, setDishCopyData } = useContext(MyContext);

    useEffect(() => {
        const currentDate = new Date();
        let startDate = new Date();

        switch (selectVal) {
            case '1':
                startDate.setMonth(currentDate.getMonth() - 1);
                break;
            case '2':
                startDate.setMonth(currentDate.getMonth() - 3);
                break;
            case '3':
                startDate.setMonth(currentDate.getMonth() - 6);
                break;
            case '4':
                startDate = new Date('2023-01-01');
                break;
            case '5':
                startDate = new Date('2022-01-01');
                break;
            default:
                startDate = new Date();
                break;
        }

        const temp = dishAllData.filter((d: any) => {
            const dishDate = new Date(d.date);

            if (selectVal === 'all' || !selectVal) {
                return true;
            } else if (selectVal === '4') {
                return dishDate >= startDate && dishDate.getFullYear() < 2024;
            } else if (selectVal === '5') {
                return dishDate >= startDate && dishDate.getFullYear() < 2023;
            }

            return dishDate >= startDate && dishDate <= currentDate;
        });

        setDishCopyData(temp);
    }, [selectVal, dishAllData, setDishCopyData]);

    return (
        <Form.Select
            onChange={(e) => setSelectVal(e.target.value)}
            className="all-select"
            aria-label="Default select example"
        >
            <option value='all'>Alle gerechten</option>
            <option value="1">Laatste maand</option>
            <option value="2">Laatste drie maanden</option>
            <option value="3">Laatste zes maanden</option>
            <option value="4">2023</option>
            <option value="5">2022</option>
        </Form.Select>
    );
};

export default Select;
