import React, { useEffect, useState } from "react";
import { deleteHelps, getHelps } from "../api/helpsApi";
import { Accordion, Button, Modal } from "react-bootstrap";
import BackBtn from "../components/BackBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const HelpAdmin: React.FC = () => {
  const [helpBox, setHelpBox] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    getHelps().then((data) => setHelpBox(data));
  }, []);

  return (
    <>
      <h1 className="header-text">Vragen</h1>
      <BackBtn />
      <p className="p-div">
        Op deze pagina kan je alle berichten zien van mensen die hulp nodig
        hebben.
      </p>
      <Accordion defaultActiveKey="0">
        {helpBox.map((help: any, i: number) => {
          return (
            <Accordion.Item key={i} eventKey={`${i}`}>
              <Accordion.Header>help #{help.id}</Accordion.Header>
              <Accordion.Body className="div-help">
                <b> Contactgegevens:</b> {help.contact}
                <p>{help.help_text}</p>
                <FontAwesomeIcon
                  size="lg"
                  className="text-danger"
                  icon={faTrash}
                  onClick={() => {
                    setShow(true);
                    setDeleteId(help.id);
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Verwijder Help #{deleteId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Ben je zeker om deze bericht te verwijderen?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Annuleren
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              await deleteHelps(deleteId);
              getHelps().then((data) => setHelpBox(data));
              setShow(false);
            }}
          >
            Verwijderen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HelpAdmin;
