import axios from "axios";

const dishUrl = `${process.env.REACT_APP_BASE_URL}/dishes`;

//GET ALL
export const allDishes = async () => {
  const result = await axios.get(dishUrl);
  return result.data;
};

//CREATE
export const addNewDish = async (data: any) => {
  console.log(data);

  const formData = new FormData();
  formData.append("image", data.image);
  formData.append("name", data.name);
  formData.append("date", data.date);
  formData.append("ingredients", data.ingredients);
  formData.append("recept", data.recept);
  formData.append("cooking_time", data.cooking_time);
  formData.append("price", data.price);
  formData.append("persons", data.persons);

  const result = await axios.post(dishUrl, formData, {
    headers: {
      Accept: "multipart/form-data",
    },
  });

  return result.data;
};

//GET ONE
export const getOneDish = async (id: any) => {
  const result = await axios.get(dishUrl + "/" + id);
  return result.data[0];
};

//get top 10
export const topTen = async () => {
  const result = await axios.get(dishUrl + "/tops");
  return result.data;
};

//UPDATE
export const editDish = async (id: any, newData: any) => {
  const formData = new FormData();
  formData.append("image", newData.newImage);
  formData.append("oldImage", newData.oldImage);
  formData.append("name", newData.name);
  formData.append("date", newData.date);
  formData.append("recept", newData.recept);
  formData.append("ingredients", newData.ingredients);
  formData.append("cooking_time", newData.cooking_time);
  formData.append("price", newData.price);
  formData.append("persons", newData.persons);

  const result = await axios.put(dishUrl + "/" + id, formData, {
    headers: {
      Accept: "multipart/form-data",
    },
  });
  return result.data;
};

// delelte
export const deleteDish = async (id: any) => {
  const result = await axios.delete(dishUrl + "/" + id);
  return result.data;
};

// rating dishes
export const ratingDish = async (data: any) => {
  if (data.id === 0) {
    return null;
  } else {
    const result = await axios.put(dishUrl + "/rate/" + data.id, data);
    return result.data;
  }
};
