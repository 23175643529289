import axios from "axios";

const helpUrl = `${process.env.REACT_APP_BASE_URL}/helps`;

export const getHelps = async () => {
  const result = await axios.get(helpUrl);
  return result.data;
};

export const createHelp = async (data: any) => {
  const result = await axios.post(helpUrl, data);
  return result.data;
};
export const deleteHelps = async (id: any) => {
  const result = await axios.delete(helpUrl + "/" + id);
  return result.data;
};
