import axios from "axios";

const ideaUrl = `${process.env.REACT_APP_BASE_URL}/ideas`;

export const getIdeas = async () => {
  const result = await axios.get(ideaUrl);
  return result.data;
};

export const createIdea = async (data: any) => {
  const result = await axios.post(ideaUrl, data);
  return result.data;
};

export const deleteIdea = async (id: any) => {
  const result = await axios.delete(ideaUrl + "/" + id);
  return result.data;
};
