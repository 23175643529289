import React, { createContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import NavbarMenu from "./components/Navbar";
import { Col, Container, Row } from "react-bootstrap";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import TopDishes from "./pages/Top";
import Footer from "./components/Footer";
import IdeaBox from "./pages/Idea";
import Search from "./pages/Search";
import Login from "./pages/Login";
import Help from "./pages/Help";
import AddDishes from "./admin/AddDishes";
import { allDishes } from "./api/dishesApi";
import AllDishes from "./admin/AllDishes";
import EditDish from "./admin/EditDish";
import OneDish from "./pages/OneDish";
import IdeaBoxAdmin from "./admin/IdeaBoxAdmin";
import HelpAdmin from "./admin/HelpAdmin";
import Cookies from "js-cookie";
import setCookies from "./hooks/setCoocies";
import getCookies from "./hooks/getCookies";
import History from "./pages/History";

export const MyContext = createContext<any | null>(null);

const App: React.FC = () => {
  const [dishAllData, setDishAllData] = useState([]);
  const [dishCopyData, setDishCopyData] = useState([]);
  const [authentication, setAuthentication] = useState(false);
  // rating cookies
  const [ratedDishes, setRatedDishes] = useState<string[]>([]);
  const history = useNavigate();

  useEffect(() => {
    allDishes().then((data) => {
      if (data) {
        setDishAllData(data);
        setDishCopyData(data);
      }
    });
  }, []);
  //////////////////////// rating cookie useEffect

  useEffect(() => {
    const ratedDishesFromCookies = getCookies("ratedDishes");
    if (ratedDishesFromCookies) {
      const parsedRatedDishes = JSON.parse(ratedDishesFromCookies);
      setRatedDishes(parsedRatedDishes);
    }
    if (!getCookies("cookie")) {
      const cookiesDiv: any = document.querySelector("#cookies");
      cookiesDiv.style.display = "block";
    }
    if (Cookies.get("user")) {
      setAuthentication(true);
    }
  }, []);

  const cookieBtnHandleAccept = () => {
    const cookiesDiv: any = document.querySelector("#cookies");
    cookiesDiv.style.display = "none";
    setCookies("cookie", true, 30);
  };
  const cookieBtnHandleClose = () => {
    const cookiesDiv: any = document.querySelector("#cookies");
    cookiesDiv.style.display = "none";
  };
  const value = {
    history,
    dishAllData,
    dishCopyData,
    setDishAllData,
    setDishCopyData,
    authentication,
    setAuthentication,
    ratedDishes,
    setRatedDishes,
  };
  return (
    <MyContext.Provider value={value as any}>
      <NavbarMenu />
      <Container className="Maincontainer">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/geschiedenis" element={<History />} />
          <Route path="/topgerechten" element={<TopDishes />} />
          <Route
            path="/idee"
            element={authentication ? <IdeaBoxAdmin /> : <IdeaBox />}
          />
          <Route path="/zoeken" element={<Search />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/hulp"
            element={authentication ? <HelpAdmin /> : <Help />}
          />
          {authentication ? (
            <>
              <Route path="/toevoegen" element={<AddDishes />} />
              <Route path="/alle" element={<AllDishes />} />
              <Route path="/edit/:id" element={<EditDish />} />
            </>
          ) : null}
          <Route path="/:id" element={<OneDish />} />
        </Routes>
      </Container>
      <Footer />
      <div id="cookies">
        <Container>
          <Row className="cookies">
            <Col className="col-12 col-lg-8">
              Deze website maakt gebruik van functionele cookies om ervoor te
              zorgen dat je de beste ervaring op onze website krijgt.
            </Col>
            <Col className="col-12 col-lg-4">
              <button onClick={cookieBtnHandleClose}>Sluiten</button>
              <button onClick={cookieBtnHandleAccept}>Accepteren</button>
            </Col>
          </Row>
        </Container>
      </div>
    </MyContext.Provider>
  );
};

export default App;
