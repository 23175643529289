import { useEffect, useState } from "react";
import { getOneDish } from "../api/dishesApi";
import { useParams } from "react-router-dom";
import { Col, Row, Spinner } from "react-bootstrap";
import BackBtn from "../components/BackBtn";
import MarkdownEditor from "@uiw/react-markdown-editor";
import error from "../assets/images/404.svg";

const OneDish = () => {
  const { id } = useParams();
  type DishItem = {
    name: string;
    recept: string;
    date: string;
    ingredients: string;
    image: string;
    cooking_time: string;
    price: number;
    rate: number;
    rate_members: number;
    persons: number;
  };
  const [oneDishItem, setOneDishItem] = useState<DishItem>({
    name: "",
    recept: "",
    date: "",
    ingredients: "",
    image: "",
    cooking_time: "",
    price: 0,
    rate: 0,
    rate_members: 0,
    persons: 1,
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  }, []);

  useEffect(() => {
    getOneDish(id).then((data) => {
      if (data === undefined) {
        return (
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. At, unde?
          </p>
        );
      }

      setOneDishItem({
        name: data.name,
        recept: data.recept,
        date: data.date,
        ingredients: data.ingredients,
        image: data.image,
        cooking_time: data.cooking_time,
        price: data.price,
        rate: data.rate,
        rate_members: data.rate_members,
        persons: data.persons,
      });
    });
  }, [id]);

  const arr = oneDishItem.ingredients
    .split(",")
    .filter((a) => a !== " ")
    .filter((b) => b !== "\r\n");

  const stem = oneDishItem.rate / oneDishItem.rate_members;

  return isLoading ? (
    <Spinner animation="border" />
  ) : (
    <section>
      {oneDishItem.name ? (
        // <h1 className="header-text">{oneDishItem.name}</h1>
        <h1 className="header-text">Gerecht</h1>
      ) : null}
      <BackBtn />
      {oneDishItem.name ? (
        <Row className="single-dishes">
          <Col className="p-0 col-12 col-md-6 text-center">
            <div
              className="div-img"
              style={{
                backgroundImage: `url(${
                  `${process.env.REACT_APP_BASE_URL}/upload/` +
                  oneDishItem.image
                })`,
              }}
            ></div>
            <h3>{oneDishItem.name}</h3>
            {[...Array(5)].map((s, index) => {
              const count = index + 1;
              return (
                <label key={index} className="star-lable">
                  <input
                    type="radio"
                    readOnly
                    disabled
                    value={count}
                    name="rating"
                  />
                  {count <= stem ? (
                    <svg
                      className="star"
                      xmlns="http://www.w3.org/2000/svg"
                      height="2em"
                      viewBox="0 0 576 512"
                    >
                      <path
                        className="bg-warning"
                        d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="star"
                      xmlns="http://www.w3.org/2000/svg"
                      height="2em"
                      viewBox="0 0 576 512"
                    >
                      <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                    </svg>
                  )}
                </label>
              );
            })}
            <p className="m-0">
              Gemiddelde: {stem.toFixed(1)} / Aantal stemmen:&nbsp;
              {oneDishItem.rate_members}
            </p>
          </Col>
          <Col className="col-12 col-md-6">
            <Row className="single-header">
              <Col lg={4}>
                <p>Datum:&nbsp;{oneDishItem.date.slice(0, 10)}</p>
              </Col>
              <Col lg={5}>
                <p>
                  Bereidingstijd:&nbsp;{oneDishItem.cooking_time}&nbsp;minuten
                </p>
              </Col>
              <Col lg={3}>
                <p>Kostprijs:&nbsp;€&nbsp;{oneDishItem.price},00 pp</p>
              </Col>
            </Row>

            <p>
              <b>Aantal personen:</b> {oneDishItem.persons}&nbsp;
              {oneDishItem.persons > 1 ? "personen" : "persoon"}
            </p>
            <b>Ingrediënten:</b>
            <div className="div-ingredient">
              <ul className="ingredient-ul-list">
                {arr?.map((i, index: number) => {
                  return <li key={index}>{i}</li>;
                })}
              </ul>
            </div>
            <b>Recept:</b>
            <div data-color-mode="light" className="App div-recept">
              <MarkdownEditor.Markdown source={oneDishItem.recept} />
            </div>
          </Col>
        </Row>
      ) : (
        <div className="text-center">
          <img className="error-img" src={error} />
        </div>
      )}
    </section>
  );
};

export default OneDish;
