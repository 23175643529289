import '../sass/_footer.scss'
const Footer: React.FC = () => {
    return (
        <div className="developer">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <a href="https://www.autimatisering.nl/" target="_blank">
                <div className="developer-text align-top">Designed and build by</div>
                <div className="developer-logo"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
}


export default Footer