import React, { useEffect, useState } from "react";
import { deleteIdea, getIdeas } from "../api/ideaApi";
import { Accordion, Button, Modal } from "react-bootstrap";
import BackBtn from "../components/BackBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const IdeaBoxAdmin = () => {
  const [ideas, setIdeas] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    getIdeas().then((data) => setIdeas(data));
  }, []);
  return (
    <>
      <h1 className="header-text">Ideeëndoos</h1>
      <BackBtn />
      <p className="p-div">
        Op deze pagina kan je alle berichten zien van mensen die lekkere ideeën
        hebben voor gerechten.
      </p>
      <Accordion defaultActiveKey="0">
        {ideas.map((idea: any, i: number) => {
          return (
            <Accordion.Item key={i} eventKey={`${i}`}>
              <Accordion.Header>Idea #{idea.id}</Accordion.Header>
              <Accordion.Body>
                <p>{idea.idea_text}</p>
                <FontAwesomeIcon
                  size="lg"
                  className="text-danger"
                  icon={faTrash}
                  onClick={() => {
                    setShow(true);
                    setDeleteId(idea.id);
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Verwijder Idee #{deleteId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Ben je zeker om deze bericht te verwijderen?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Annuleren
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              await deleteIdea(deleteId);
              getIdeas().then((data) => setIdeas(data));
              setShow(false);
            }}
          >
            Verwijderen
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IdeaBoxAdmin;
