import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../assets/images/Logo.svg";
import "../test.scss";
import { useContext } from "react";
import { MyContext } from "../App";
import { Link, useLocation } from "react-router-dom";
import removeCookies from "../hooks/removeCookies";
const NavbarMenu: React.FC = () => {
  const { authentication, setAuthentication } = useContext(MyContext);
  const location = useLocation();
  const isActiveLink = (pathname: string) => {
    return location.pathname === pathname ? "navbar-items-active" : "";
  };

  const LoguitHandle = () => {
    removeCookies("user");
    setAuthentication(false);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="fixed-top bg-body-tertiary "
    >
      <Container>
        <Navbar.Brand className="col-sm" href="/">
          <img src={Logo} alt="logo" className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <span>
            <svg
              style={{ width: "24px" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M61.1 224C45 224 32 211 32 194.9c0-1.9 .2-3.7 .6-5.6C37.9 168.3 78.8 32 256 32s218.1 136.3 223.4 157.3c.5 1.9 .6 3.7 .6 5.6c0 16.1-13 29.1-29.1 29.1H61.1zM144 128a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm240 16a16 16 0 1 0 0-32 16 16 0 1 0 0 32zM272 96a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zM16 304c0-26.5 21.5-48 48-48H448c26.5 0 48 21.5 48 48s-21.5 48-48 48H64c-26.5 0-48-21.5-48-48zm16 96c0-8.8 7.2-16 16-16H464c8.8 0 16 7.2 16 16v16c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V400z" />
            </svg>
          </span>
        </Navbar.Toggle>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end "
        >
          <Nav>
            <Nav.Link href="/" className={`navbar-items ${isActiveLink("/")}`}>
              Laatste
            </Nav.Link>
            <Nav.Link
              href="/geschiedenis"
              className={`navbar-items ${isActiveLink("/geschiedenis")}`}
            >
              Geschiedenis
            </Nav.Link>
            <Nav.Link
              href="/topgerechten"
              className={`navbar-items ${isActiveLink("/topgerechten")}`}
            >
              Top gerechten
            </Nav.Link>
            <Nav.Link
              href="/zoeken"
              className={`navbar-items ${isActiveLink("/zoeken")}`}
            >
              Zoeken
            </Nav.Link>
            {!authentication ? (
              <>
                <Nav.Link
                  href="/idee"
                  className={`navbar-items ${isActiveLink("/idee")}`}
                >
                  Ideeëndoos
                </Nav.Link>
                <Nav.Link
                  href="/hulp"
                  className={`navbar-items ${isActiveLink("/hulp")}`}
                >
                  Hulp nodig?
                </Nav.Link>
              </>
            ) : null}
            {authentication ? (
              <NavDropdown
                align={{ lg: "end" }}
                title="Administratie"
                id="collapsible-nav-dropdown"
              >
                <NavDropdown.Item>
                  <Link
                    to="/login"
                    className={`drop-items ${isActiveLink("/login")}`}
                  >
                    Portaal
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to="/toevoegen"
                    className={`drop-items ${isActiveLink("/toevoegen")}`}
                  >
                    Gerecht toevoegen
                  </Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Link
                    to="/alle"
                    className={`drop-items ${isActiveLink("/alle")}`}
                  >
                    Alle gerechten
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    to="/idee"
                    className={`drop-items ${isActiveLink("/idee")}`}
                  >
                    Ideeëndoos
                  </Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Link
                    to="/hulp"
                    className={`drop-items ${isActiveLink("/hulp")}`}
                  >
                    Vragen
                  </Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                  <Link
                    to={"/"}
                    className={`drop-items ${isActiveLink("/")}`}
                    onClick={LoguitHandle}
                  >
                    Log uit
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link
                href="/login"
                className={`navbar-items ${isActiveLink("/login")}`}
              >
                Login
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarMenu;
