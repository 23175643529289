import { useEffect, useState } from "react";
import { Alert, Button, FloatingLabel, Form } from "react-bootstrap";
import { createIdea } from "../api/ideaApi";
import setCookies from "../hooks/setCoocies";
import getCookies from "../hooks/getCookies";

const IdeaBox: React.FC = () => {
  const [newIdea, setNewIdea] = useState({
    idea_text: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showError, setShowError] = useState(false);

  const ideaHandle = async (e: any) => {
    e.preventDefault();
    if (newIdea.idea_text !== "") {
      await createIdea(newIdea);
      setShowAlert(true);
      setNewIdea({
        idea_text: "",
      });
      setCookies("idea_cookies", true, 1);
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      return () => clearTimeout(timer);
    } else {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  };

  const idea_cookie = getCookies("idea_cookies");

  return (
    <>
      <h1 className="header-text">Ideeëndoos</h1>
      <div className="text-center">
        {idea_cookie !== "true" ? (
          <>
            <p className="p-div">
              {showAlert && (
                <Alert id="success-alert" key={"success"} variant={"success"}>
                  Uw idee is gestuurd.
                </Alert>
              )}
              {showError && (
                <Alert id="danger-alert" key={"danger"} variant={"danger"}>
                  Error.
                </Alert>
              )}
              Heb jij nog ideeën voor lekkere gerechten die ze bij Switch kunnen
              maken? Let us know!
            </p>
            <FloatingLabel
              className="mx-auto w-sm-75"
              controlId="floatingTextarea2"
              label="Bijvoorbeeld: Taco’s, Lasagne, etc."
            >
              <Form.Control
                value={newIdea.idea_text}
                onChange={(e) =>
                  setNewIdea((p) => ({ ...p, idea_text: e.target.value }))
                }
                as="textarea"
                placeholder="Bijvoorbeeld: Taco’s, Lasagne, etc."
                style={{ height: "100px" }}
              />
            </FloatingLabel>
            <Button
              className="buttons"
              onClick={(e) => ideaHandle(e)}
              variant="dark"
            >
              Verzenden
            </Button>
          </>
        ) : (
          <p className="p-div">
            Bedankt voor jouw bericht!
            <br /> Je kunt slechts één keer per dag een idee insturen. Nog een
            leuk idee dat per se gemeld moet worden?
            <br /> Stuur hem dan morgen in, en wij kijken of we dat kunnen gaan
            realiseren.
          </p>
        )}
      </div>
    </>
  );
};

export default IdeaBox;
