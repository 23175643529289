import { useContext, useState } from "react";
import { MyContext } from "../App";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { allDishes, deleteDish } from "../api/dishesApi";
import BackBtn from "../components/BackBtn";
import Select from "../components/Select";
const AllDishes = () => {
  const { dishCopyData, history, setDishAllData, setDishCopyData } =
    useContext(MyContext);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  return (
    <article>
      <h1 className="header-text">Alle gerechten</h1>
      <BackBtn />
      <Select />
      <Row className="all-row">
        {dishCopyData?.map((element: any, i: number) => {
          const { id, name, image } = element;
          return (
            <Col className="col-md-4 col-lg-3" key={i}>
              <div className="all-cols">
                <Link to={`/${id}`}>
                  {" "}
                  <div
                    className="all-img"
                    style={{
                      backgroundImage: `url(${
                        `${process.env.REACT_APP_BASE_URL}/upload/` + image
                      })`,
                    }}
                  ></div>
                </Link>
                {name}
                <div className="all-btns">
                  <Button
                    onClick={() => history(`/edit/${id}`)}
                    variant="success"
                  >
                    Bijwerken
                  </Button>
                  <Button
                    onClick={() => {
                      setShow(true);
                      setDeleteId(id);
                    }}
                    variant="danger"
                  >
                    Verwijderen
                  </Button>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Verwijder maaltijd</Modal.Title>
        </Modal.Header>
        <Modal.Body>Ben je zeker om deze maaltijd te verwijderen?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Annuleren
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              await deleteDish(deleteId);
              allDishes().then((data) => {
                setDishAllData(data);
                setDishCopyData(data);
              });
              setShow(false);
            }}
          >
            Verwijderen
          </Button>
        </Modal.Footer>
      </Modal>
    </article>
  );
};

export default AllDishes;
